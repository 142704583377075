import { Link } from 'gatsby'
import { useState } from 'react'
import Header from '../components/Header'
import create from '../images/createDao/create.png'
import open from '../images/createDao/open.png'
import Modal from '../components/Popup'

const CreateDao: React.FC = () => {
  // const [createOrgModalVisible, setCreateOrgModalVisible] = useState(false)
  const [openOrgModalVisible, setOpenOrgModalVisible] = useState(false)

  const onOkOpenOrgModal = () => {
    setOpenOrgModalVisible(false)
  }
  const onCancelOpenOrgModal = () => {
    setOpenOrgModalVisible(false)
  }

  return (
    <div className="overflow-hidden font-regularText">
      <Header />
      <div className="mt-20 sm:mt-[57px] flex sm:bg-gradient-to-t sm:from-[rgba(255,255,255,0.19)] sm:to-[rgba(234,244,252,0.71)]">
        <section className="w-[510px] m-auto mt-[75px] mb-[130px] sm:px-6">
          {/* Title */}
          <div className="h-[52px] font-boldText text-[34px] leading-[52px] text-center">
            WELCOME TO CTIO
          </div>
          {/* Desc */}
          <div className="mt-3 text-[#333] text-[18px] leading-[32px] text-center">
            Create your own organization in a few minutes!
          </div>
          {/* Create Org */}
          <Link
            to="/createOrganization"
            className="h-[157px] bg-gradient-to-t from-[rgba(255,255,255,0.19)] to-[rgba(234,244,252,0.71)] mt-[46px] px-[45px] pt-[45px] pb-[48px] rounded-2xl shadow-[16px_16px_32px_1px_rgba(0,0,0,0.04)] flex cursor-pointer sm:h-[139px] sm:mt-[25px] sm:py-8 sm:px-[14px]"
          >
            <img
              src={create}
              alt="CreateOrganization"
              className="mr-[21px] w-16 h-16"
            />
            <div className="flex flex-col justify-center">
              <div className="text-[18px] leading-[32px] text-[#1E94FC] font-boldText">
                Create an organization
              </div>
              <div className="text-[#828282] text-sm">
                Start your organization with Aragon
              </div>
            </div>
          </Link>
          {/* Open Org */}
          <div
            className="h-[157px] bg-gradient-to-t from-[rgba(255,255,255,0.19)] to-[rgba(234,244,252,0.71)] mt-6 px-[45px] pt-[45px] pb-[48px] rounded-2xl shadow-[16px_16px_32px_1px_rgba(0,0,0,0.04)] flex cursor-pointer sm:mt-[25px] sm:py-8 sm:px-[14px]"
            onClick={() => setOpenOrgModalVisible(true)}
          >
            <img
              src={open}
              alt="CreateOrganization"
              className="mr-[21px] w-16 h-16"
            />
            <div className="flex flex-col justify-center">
              <div className="text-[18px] leading-[32px] text-[#1E94FC] font-boldText">
                Open an existing organization
              </div>
              <div className="text-[#828282] text-sm">
                See more about your organi- zation
              </div>
            </div>
          </div>
          {/* Tips Text */}
          <div className="flex mt-[58px] justify-center text-center items-center text-[14px] sm:mt-[50px] sm:flex-col sm:text-xs">
            <div className="mr-1">Do you need more information about CTIO?</div>
            <Link to="/" className="text-[#EB5757]">
              Visit our homepage
            </Link>
          </div>
        </section>
      </div>
      {/* Open Modal */}
      <Modal
        visible={openOrgModalVisible}
        onOk={onOkOpenOrgModal}
        onCancel={onCancelOpenOrgModal}
      >
        <div className="font-boldText text-[34px] sm:mt-[42px] text-center sm:text-[26px]">
          Name of existing organization
        </div>
        <div className="mt-[33px] h-[54px] bg-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded flex items-center justify-between p-4 sm:mt-[40px]">
          <input
            type="text"
            className="flex-1 outline-none text-[14px] text-[#828282]"
            placeholder="Type an organization name"
          />
          <div className="before:ml-2 before:mr-2 before:border-l before:border-solid before:border-[#E0E0E0]">
            .aragonid.eth
          </div>
        </div>
        <button
          className="mt-[33px] w-[192px] h-[54px] bg-gradient-to-r from-[rgba(174,216,255,1)] to-[rgba(30,148,252,1)] text-white rounded sm:mt-[46px] sm:mb-[48px] sm:w-full"
          onClick={() => setOpenOrgModalVisible(false)}
        >
          Open organization
        </button>
      </Modal>
    </div>
  )
}

export default CreateDao
